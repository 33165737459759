import React, { useState, useEffect } from "react";
// Chakra imports
import {
  Flex,
  Table,
  Tbody,
  Icon,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  Image,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormControl,
  FormLabel,
  Textarea,
  FormErrorMessage,
} from "@chakra-ui/react";

// Custom components
import { API_BASE_URL } from "config";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";

// Table Components
import TablesProjectRow from "components/Tables/TablesProjectRow";
import TablesTableRow from "components/Tables/TablesTableRow";

// Data
import { tablesProjectData, tablesTableData } from "variables/general";

// Icons
import { AiFillCheckCircle } from "react-icons/ai";

import { useHistory } from 'react-router-dom';

function Tables() {
  const history = useHistory();
  const [ads, setAds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [editingAd, setEditingAd] = useState(null);

  // Function to handle edit
  const handleEdit = (ad) => {
    setEditingAd(ad);
    console.log(editingAd)
    onOpen(); // Open the modal
  };

  const handleAddPostButton = () => {
    setEditingAd(null)
  }

  const handleDelete = async (ad) => {

    try {
      const token = localStorage.getItem('token');
        const response = await fetch(`${API_BASE_URL}/admin/ads/${ad._id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          },
            method: 'DELETE',
        });

        if (!response.ok) {
          if (response.status === 401) {
            // Navigate to the sign-in page
            history.push('/auth/signin');
            return;
          }
            throw new Error('Failed to delete ad');
        }
        fetchAds();
        alert('Deleted Successfully');
    } catch (error) {
        console.error('Error deleting ad:', error);
    }
  };

  const fetchAds = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/admin/ads`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      if (!response.ok) {
        if (response.status === 401) {
          // Navigate to the sign-in page
          history.push('/auth/signin');
          return;
        }
        throw new Error("Failed to fetch ads");
      }
      const data = await response.json();
      console.log("testing", data);
      setAds(data.ads);
    } catch (error) {
      console.error("Error fetching ads:", error);
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    fetchAds();
  }, []);


  const [formData, setFormData] = useState({
    name: '',
    description: '',
  });
  const [imageFile, setImageFile] = useState(null);
  const [videoFile, setVideoFile] = useState(null);
  const [shirtImageFile, setShirtImageFile] = useState(null);
  const [floorImageFile, setFloorImageFile] = useState(null);
  const [errors, setErrors] = useState({});

  const handleFileChange = (e) => {
    if (e.target.name === 'image') {
      setImageFile(e.target.files[0]);
    } else if (e.target.name === 'video') {
      setVideoFile(e.target.files[0]);
    }
  };

  const handleShirtImageFileChange = (e) => {
    // if (e.target.name === 'image') {
      setShirtImageFile(e.target.files[0]);
    // }
  };

  const handleFloorImageFileChange = (e) => {
    // if (e.target.name === 'image') {
      setFloorImageFile(e.target.files[0]);
    // }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    
    const data = new FormData();
    
    // Log the current state of formData and editingAd
    console.log("formData:", formData);
    console.log("editingAd:", editingAd);
  
    // Append name and description to FormData
    if (formData.name === '') {
      data.append('name', editingAd.name);
    } else {
      data.append('name', formData.name);
    }
  
    if (formData.description === '') {
      data.append('description', editingAd.description);
    } else {
      data.append('description', formData.description);
    }
  
    // Append files if they are selected
    if (imageFile) {
      console.log("Appending imageFile:", imageFile);
      data.append('image', imageFile);
    }
    if (videoFile) {
      console.log("Appending videoFile:", videoFile);
      data.append('video', videoFile);
    }
    if (shirtImageFile) {
      console.log("Appending shirtImageFile:", shirtImageFile);
      data.append('shirt_image', shirtImageFile);
    }
    if (floorImageFile) {
      console.log("Appending floorImageFile:", floorImageFile);
      data.append('floor_image', floorImageFile);
    }
  
    // Log the FormData entries
    // for (let [key, value] of data.entries()) {
    //   console.log(key, value);
    // }
  
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/admin/ads/${editingAd._id}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        method: 'PUT', // Assuming the update operation is handled via PUT method
        body: data, // Note: When using FormData, you don't manually set 'Content-Type'
      });
  
      if (!response.ok) {
        if (response.status === 401) {
          // Navigate to the sign-in page
          history.push('/auth/signin');
          return;
        }else{
          const errorData = await response.json();
          setErrors(errorData.errors); // Assume error structure matches our state
          return; // Prevent closing modal if there are errors
        }
       
      }
  
      onClose(); // Optionally close the modal on success
      fetchAds();
      // Handle success response
    } catch (error) {
      console.error('Submission error:', error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData)
    const data = new FormData();
    // Append files if they are selected
    if (imageFile) data.append('image', imageFile);
    if (videoFile) data.append('video', videoFile);
    if(shirtImageFile) data.append('shirt_image',shirtImageFile);
    if(floorImageFile) data.append('floor_image',floorImageFile);
    // Append other text fields
    Object.keys(formData).forEach((key) => {
      data.append(key, formData[key]);
    });

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/admin/ads`, {
        headers: {
          'Authorization': `Bearer ${token}`
        },
        method: 'POST',
        body: data, // Note: When using FormData, you don't manually set 'Content-Type'
      });

      if (!response.ok) {
        if (response.status === 401) {
          // Navigate to the sign-in page
          history.push('/auth/signin');
          return;
        }else{
          const errorData = await response.json();
          setErrors(errorData.errors); // Assume error structure matches our state
          return; // Prevent closing modal if there are errors
        }
       
      };

      onClose(); // Optionally close the modal on success
      fetchAds();
      // Handle success response
    } catch (error) {
      console.error('Submission error:', error);
    }
  };
  
  return (
    <Flex direction='column' pt={{ base: "120px", md: "75px" }}>

<Button onClick={() => {onOpen(),handleAddPostButton()}} alignSelf='flex-end' mb='4' colorScheme='blue'>
        Add Post
      </Button>
      {/* Authors Table */}
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} pb='0px'>
        <CardHeader p='6px 0px 22px 0px'>
          <Text fontSize='lg' color='#fff' fontWeight='bold'>
            Adds List
          </Text>
        </CardHeader>
        <CardBody>
          <Table variant='simple' color='#fff'>
            <Thead>
              <Tr my='.8rem' ps='0px' color='gray.400'>
                <Th
                  ps='0px'
                  color='gray.400'
                  fontFamily='Plus Jakarta Display'
                  borderBottomColor='#56577A'>
                  Name
                </Th>
                <Th
                  color='gray.400'
                  fontFamily='Plus Jakarta Display'
                  borderBottomColor='#56577A'>
                  Description
                </Th>
                <Th
                  color='gray.400'
                  fontFamily='Plus Jakarta Display'
                  borderBottomColor='#56577A'>
                  Image
                </Th>
                <Th
                  color='gray.400'
                  fontFamily='Plus Jakarta Display'
                  borderBottomColor='#56577A'>
                  Video
                </Th>
                <Th
                  color='gray.400'
                  fontFamily='Plus Jakarta Display'
                  borderBottomColor='#56577A'>
                  Shirt Image
                </Th>
                <Th
                  color='gray.400'
                  fontFamily='Plus Jakarta Display'
                  borderBottomColor='#56577A'>
                  Floor Image
                </Th>
                <Th
                  color='gray.400'
                  fontFamily='Plus Jakarta Display'
                  borderBottomColor='#56577A'>
                  Action
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {ads.map((ad,key) => {
                return (
                  <TablesTableRow
                  key={ad.key}
                  name={ad.name}
                  description={ad.description}
                  image={ad.image}
                  shirt_image={ad.shirt_image}
                  floor_image={ad.floor_image}
                  ad={ad}
                  video={ad.video}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
      {/* Projects Table */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="rgba(6, 11, 40, 0.94)" color="white">
          <form onSubmit={editingAd ? handleUpdate : handleSubmit}>
            <ModalHeader>Add a New Post</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6}>
              <FormControl  isInvalid={!!errors.name}>
                <FormLabel>Name</FormLabel>
                <Input name="name" defaultValue={editingAd ? editingAd.name : formData.name} onChange={handleChange} placeholder="Name" />
                {errors.name && <FormErrorMessage>{errors.name}</FormErrorMessage>}
              </FormControl>

              <FormControl mt={4} isInvalid={!!errors.image}>
                <FormLabel>Image</FormLabel>
                <Input type="file" name="image" onChange={handleFileChange} />
                {errors.image && <FormErrorMessage>{errors.image}</FormErrorMessage>}

                {editingAd && <Image src={editingAd.image} width={100} height={100} alt="Image" />}
                
              </FormControl>

              <FormControl mt={4} isInvalid={!!errors.video}>
                <FormLabel>Video</FormLabel>
                <Input type="file" name="video" onChange={handleFileChange} />
                {errors.video && <FormErrorMessage>{errors.video}</FormErrorMessage>}

               {editingAd && <video width="100" height="100" controls>
                              <source src={editingAd.video} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>} 

              </FormControl>

              <FormControl mt={4} isInvalid={!!errors.shirt_image}>
                <FormLabel>Shirt Image</FormLabel>
                <Input type="file" name="shirt_image" onChange={handleShirtImageFileChange} />
                {errors.shirt_image && <FormErrorMessage>{errors.shirt_image}</FormErrorMessage>}

                {editingAd && <Image src={editingAd.shirt_image} width={100} height={100} alt="Image" />}
              </FormControl>

              <FormControl mt={4} isInvalid={!!errors.floor_image}>
                <FormLabel>Floor Image</FormLabel>
                <Input type="file" name="floor_image" onChange={handleFloorImageFileChange} />
                {errors.floor_image && <FormErrorMessage>{errors.floor_image}</FormErrorMessage>}

                {editingAd && <Image src={editingAd.floor_image} width={100} height={100} alt="Image" />}
              </FormControl>
              
              <FormControl mt={4} isInvalid={!!errors.description}>
                <FormLabel>Description</FormLabel>
                <Textarea name="description" defaultValue={editingAd ? editingAd.description : formData.description} onChange={handleChange} placeholder="Describe the post..." />
                {errors.description && <FormErrorMessage>{errors.description}</FormErrorMessage>}
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} type="submit">
                Submit
              </Button>
              <Button colorScheme="blue" onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

export default Tables;
