import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Canvas } from '@react-three/fiber';
import { Suspense } from 'react';
import { OrbitControls , PerspectiveCamera} from '@react-three/drei';
import fetchCharacterDetails from '../services/characterDetailService';
import Model3D from './Model3D';
import './CharacterDetail.css'; // Create a separate CSS file for styling

const CharacterDetail = () => {
  const { id } = useParams();
  const [characterData, setCharacterData] = useState(null);
  const [currentModels, setCurrentModels] = useState({
    torso: { modelUrl: null, textureUrl: null },
    pants: { modelUrl: null, textureUrl: null },
    shoes: { modelUrl: null, textureUrl: null },
  });

  useEffect(() => {
    const loadCharacterData = async () => {
      const data = await fetchCharacterDetails(id);
      setCharacterData(data);
    };
    loadCharacterData();
  }, [id]);

  const applyModel = (part, modelUrl, textureUrl) => {
    setCurrentModels((prev) => ({
      ...prev,
      [part]: { modelUrl, textureUrl }, 
    }));
  };

  if (!characterData) return <div className="loading-screen">Loading...</div>;

  const { character, tops, bottoms, shoes } = characterData;

  return (
    <div className="character-detail-container">
      <div className="character-view">
        <h1 className="character-name">{character.modelName}</h1>
        <Suspense fallback={<div>Loading...</div>}>
          <Canvas className="character-canvas">
            <ambientLight intensity={0.5} />
            <directionalLight position={[5, 5, 5]} intensity={1.5} castShadow />
            <pointLight position={[-10, -10, -10]} intensity={0.7} />
            <PerspectiveCamera 
              makeDefault 
              position={[0, 2, 5]}  // Adjust camera distance
              fov={50}               // Field of view to fit the model
            />
            <Model3D 
              url={character.threeDModel} 
              models={currentModels}
              position={[0, -1, 0]}  // Center model at the bottom
              scale={[1, 1, 1]} // Increase the model's size
            />
            <OrbitControls 
             />
          </Canvas>
        </Suspense>
      </div>
      <div className="character-customization">
        <h3 className="section-title">Tops</h3>
        <div className="item-grid">
          {tops.map((top) => (
            <img
              key={top.name}
              src={top.image}
              alt={top.name}
              className={`item-icon ${top.threeDModel ? 'clickable' : 'disabled'}`}
              onClick={() => top.threeDModel && applyModel('torso', top.threeDModel, top.texture)}
            />
          ))}
        </div>
        <h3 className="section-title">Bottoms</h3>
        <div className="item-grid">
          {bottoms.map((bottom) => (
            <img
              key={bottom.name}
              src={bottom.image}
              alt={bottom.name}
              className={`item-icon ${bottom.threeDModel ? 'clickable' : 'disabled'}`}
              onClick={() => bottom.threeDModel && applyModel('pants', bottom.threeDModel, bottom.texture)}
            />
          ))}
        </div>
        <h3 className="section-title">Shoes</h3>
        <div className="item-grid">
          {shoes.map((shoe) => (
            <img
              key={shoe.name}
              src={shoe.image}
              alt={shoe.name}
              className={`item-icon ${shoe.threeDModel ? 'clickable' : 'disabled'}`}
              onClick={() => shoe.threeDModel && applyModel('shoes', shoe.threeDModel, shoe.texture)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CharacterDetail;
