import React, { useRef, useEffect } from 'react';
import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { MeshStandardMaterial, DoubleSide, TextureLoader } from 'three';

const Model3D = ({ url, models }) => {
  const modelRef = useRef();

  // Function to dynamically determine the loader based on file extension
  const getLoader = (modelUrl) => {
    const extension = modelUrl.split('.').pop().toLowerCase();
    if (extension === 'fbx') return FBXLoader;
    if (extension === 'gltf' || extension === 'glb') return GLTFLoader;
    throw new Error(`Unsupported file type: ${extension}`);
  };

  // Always load the base character model
  const characterModel = useLoader(getLoader(url), url, (loader) => {
    if (loader instanceof GLTFLoader) {
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath('/draco/');
      loader.setDRACOLoader(dracoLoader);
    }
  });

  // Load the clothing models
  const torsoModelUrl = models.torso?.modelUrl || url;
  const pantsModelUrl = models.pants?.modelUrl || url;
  const shoesModelUrl = models.shoes?.modelUrl || url;

  const torsoModel = useLoader(getLoader(torsoModelUrl), torsoModelUrl);
  const pantsModel = useLoader(getLoader(pantsModelUrl), pantsModelUrl);
  const shoesModel = useLoader(getLoader(shoesModelUrl), shoesModelUrl);

  // Log the loaded textures
  const torsoTexture = useLoader(TextureLoader, models.torso?.textureUrl || 'https://www.portal.seiky.in/seiky-backend/uploads/top_characters/textures/texture-1728020781008-762773435.png');
  const pantsTexture = useLoader(TextureLoader, models.pants?.textureUrl || 'https://www.portal.seiky.in/seiky-backend/uploads/top_characters/textures/texture-1728020781008-762773435.png');
  const shoesTexture = useLoader(TextureLoader, models.shoes?.textureUrl || 'https://www.portal.seiky.in/seiky-backend/uploads/top_characters/textures/texture-1728020781008-762773435.png');

  // Log loaded textures to confirm they exist
  console.log("Torso texture loaded:", torsoTexture);
  console.log("Pants texture loaded:", pantsTexture);
  console.log("Shoes texture loaded:", shoesTexture);

  // Helper function to return the correct object (FBX or GLTF format)
  const getModelScene = (model) => model?.scene || model;

  // Custom function to replace a child object with the new model and texture
  const replacePart = (parent, childName, newModel, texture = null) => {
    const originalChild = parent.getObjectByName(childName);
    if (originalChild) {
      console.log(`Replacing ${childName}`);
      parent.remove(originalChild);
    }
    
    // Traverse the new model to find all meshes and apply the texture
    newModel.traverse((child) => {
      if (child.isMesh) {
        if (texture) {
          console.log(`Applying texture to ${child.name}, texture.flipY: ${texture.flipY}`);
          child.material = new MeshStandardMaterial({
            map: texture,
            metalness: 0.5,
            roughness: 0.5,
            side: DoubleSide,
          });
        }
      }
    });
    
    parent.add(newModel);
  };
  
  useEffect(() => {
    if (modelRef.current) {
      const model = modelRef.current;
      modelRef.current.position.y = -2.9;
      // modelRef.current.position.x = -3.5;
      modelRef.current.scale.set(0.03,0.03,0.03)

      // Apply default material if none exists
      model.traverse((child) => {
        if (child.isMesh && !child.material.isMeshStandardMaterial) {
          console.log(`Applying default material to ${child.name}`);
          child.material = new MeshStandardMaterial({
            color: '#f5cba7',
            metalness: 0.5,
            roughness: 0.5,
            side: DoubleSide,
          });
        }
      });

      // Replace torso if selected
      if (models.torso?.modelUrl) {
        const torsoScene = getModelScene(torsoModel);
        replacePart(model, 'TORSO_01', torsoScene, torsoTexture);
      }

      // Replace pants if selected
      if (models.pants?.modelUrl) {
        const pantsScene = getModelScene(pantsModel);
        replacePart(model, 'PANTS_01', pantsScene, pantsTexture);
      }

      // Replace shoes if selected
      if (models.shoes?.modelUrl) {
        const shoesScene = getModelScene(shoesModel);
        replacePart(model, 'SHOES_01', shoesScene, shoesTexture);
      }
    }
  }, [torsoModel, pantsModel, shoesModel, models, torsoTexture, pantsTexture, shoesTexture]);

  return <primitive ref={modelRef} object={getModelScene(characterModel)} scale={0.1} />;
};

export default Model3D;
